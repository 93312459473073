<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>故障上报</span>
      </div>
      <!-- <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="所属区域">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="册本">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结果">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
          <el-table-column prop="reportPerson" label="上报者姓名"></el-table-column>
          <el-table-column prop="phone" label="上报者手机"></el-table-column>
          <el-table-column prop="reportTime" label="上报时间">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="processingStatus" label="处理状态">
            <template slot-scope="scope">
              <el-tag size="small" type="warning" v-show="scope.row.processingStatus==0">未处理</el-tag>
              <el-tag size="small" type="success" v-show="scope.row.processingStatus==1">已处理</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="processingResult" label="处理结果">
            <template slot-scope="scope">
              <el-tag size="small" type="warning" v-show="scope.row.processingResult==0">未解决</el-tag>
              <el-tag size="small" type="success" v-show="scope.row.processingResult==1">已解决</el-tag>
              <el-tag size="small" type="danger" v-show="scope.row.processingResult==2">误报</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="processingPersonName" label="处理人员">
            <template slot-scope="scope">
              {{ scope.row.processingPersonName?scope.row.processingPersonName:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="processingTime" label="处理时间">
            <template slot-scope="scope">
              {{ scope.row.processingTime ? dayjs(scope.row.processingTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="source" label="来源">
            <template slot-scope="scope">
              <span v-show="scope.row.source==0">APP</span>
              <span v-show="scope.row.source==1">微信</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div v-show="scope.row.processingResult!=1">
                    <el-dropdown-item><div @click="turnFault(scope.row)">转指派</div></el-dropdown-item>
                  </div>
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [
        {
          questionId: 1637048546751,
          waterMeterNo: 'TK-0546',
          reportName: '李瑞',
          reportPhone: '18267951346',
          reportTime: 1637900928533,
          region: '城南-B区',
          staffName: ['王欢'],
          status: '未处理',
          result: '未解决',
          processTime: 1637990028000
        },
        {
          questionId: 1637048585246,
          waterMeterNo: 'FH-8465',
          reportName: '张静',
          reportPhone: '15379468532',
          reportTime: 1637799872000,
          region: '城北-A区',
          staffName: ['李想'],
          status: '已处理',
          result: '已解决',
          processTime: 1637897655000
        },
        {
          questionId: 1637048567495,
          waterMeterNo: 'BD-8465',
          reportName: '黄炜',
          reportPhone: '17246958465',
          reportTime: 1637475775000,
          region: '城东-C区',
          staffName: ['刘巍'],
          status: '已处理',
          result: '已解决',
          processTime: 1637577265000
        }
      ],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    // 故障转指派
    turnFault(row) {
      this.$router.push({ name: 'direcFaultTurnAdd', params: {row: row}})
    },
    details(row) {
      this.$router.push({ name: 'faultReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('faulReportQuery', {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>